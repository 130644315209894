import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IListResponseData, LanguageEnum, SERVICES_API } from '@acd-loreal/data';
import { IPharmacy } from '../models/pharmacy/pharmacy';
import { environment } from '../../environments/environment';
import { IBenefitCategoriesDropdown } from '../models/benefits/benefit-categories-dropdown';
import { IBenefitMarkDropdown } from '../models/benefits/benefit-mark-dropdown';
import { IBenefitTypeDropdown } from '../models';
import { IBenefitsList } from '../models/benefits/benefits-list';
import { Store } from '@ngxs/store';
import { AppState } from '../app.state';
import { IChallengesList } from '../pages/core/pages/account/pages/__challenges/models/challenges-list';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  constructor(
    @Inject(SERVICES_API)
    private readonly _servicesApi: string,
    private readonly _httpClient: HttpClient,
    private readonly _store: Store,
  ) {}

  /**
   * Get list of pharmacy networks.
   */
  public getPharmacyNetworks(): Observable<{ id: number; name: string }[]> {
    return this._httpClient.get<{ id: number; name: string }[]>(
      `${this._servicesApi}/platform/pharmacy/network/dropdown`,
    );
  }

  /**
   * Search pharmacy by any data
   */
  public searchPharmacies(
    search: string,
    pharmacy_network_id?: string,
    countrySearch?: LanguageEnum,
    zip_code?: string,
    city?: string,
  ): Observable<IPharmacy[]> {
    const country: LanguageEnum = countrySearch ? countrySearch : this._store.selectSnapshot(AppState.language);
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    if (pharmacy_network_id) {
      params = params.set('pharmacy_network_id', pharmacy_network_id);
    }
    if (country) {
      params = params.set('country', country);
    }
    if (zip_code) {
      params = params.set('zip_code', zip_code);
    }
    if (city) {
      params = params.set('city', city);
    }
    return this._httpClient.get<IPharmacy[]>(`${this._servicesApi}/platform/pharmacy/dropdown`, {
      params: params,
    });
  }

  /**
   * Get list of benefit categories
   */
  public getAllBenefitCategories(): Observable<IBenefitCategoriesDropdown[]> {
    return this._httpClient.get<IBenefitCategoriesDropdown[]>(`${environment.api}/platform/award-category/dropdown`);
  }

  /**
   * Get list of benefit categories
   */
  public getAllBenefitMarks(): Observable<IBenefitMarkDropdown[]> {
    return this._httpClient.get<IBenefitMarkDropdown[]>(`${environment.api}/platform/award-mark/dropdown`);
  }

  /**
   * Get list of benefit types
   */
  public getAllBenefitTypes(): Observable<IBenefitTypeDropdown[]> {
    return this._httpClient.get<IBenefitTypeDropdown[]>(`${environment.api}/platform/award-type/dropdown`);
  }

  public getBenefitsForDashboard(): Observable<IBenefitsList[]> {
    return this._httpClient
      .get<IListResponseData<IBenefitsList>>(`${environment.api}/platform/awards`, {
        params: {
          perPage: 5,
          page: 1,
          direction: 'asc',
          sort: 'name',
        },
      })
      .pipe(map((value) => value.data));
  }

  public getChallengesForDashboard(selectedPharmacyUuid: string): Observable<IChallengesList[]> {
    return this._httpClient
      .get<IListResponseData<IChallengesList>>(`${environment.api}/platform/challenge/${selectedPharmacyUuid}/list`, {
        params: {
          perPage: 5,
          page: 1,
          direction: 'asc',
          sort: 'title',
        },
      })
      .pipe(map((value) => value.data));
  }
}
