import { RouteSlug } from './models';

export const APP_COOKIES_KEY = 'app.cookiesAllowed';
export const APP_JWT_TOKEN = 'app.token';
export const APP_TOKEN_EXPIRED_AT = 'app.tokenExpiredAt';
export const APP_LANGUAGE_KEY = 'app.language';
export const APP_BENEFITS_SHOPPING_CART = 'app.benefitsShoppingCart';
export const APP_PHARMACY_UUID = 'app.selectedPharmacyUuid';
export const APP_ACD_ADDRESS_AFTER_LOGGING_IN = 'app.addressAfterLoggingIn';
export const APP_SOCIAL_SURVEY_MODAL_DISPLAYED = 'app.socialSurveyModalDisplayed';
export const MENOPAUSE_ANSWER = 'menopause.form.model.answer';
export const SCIENCE_PHARMA_REGISTRATION_TYPE_AFTER_ENTERING_PAGE = 'sciencePharma.registrationTypeAfterEnteringPage';
export const SUN_COMPETITION_ACTUAL_QUESTION = 'SunCompetition.actualQuestion';
export const SUN_COMPETITION_SESSION_ID = 'SunCompetition.sunSessionId';
export const SUN_COMPETITION_ANS_1 = 'SunCompetition.answer1';
export const SUN_COMPETITION_ANS_2 = 'SunCompetition.answer2';
export const SUN_COMPETITION_ANS_3 = 'SunCompetition.answer3';
export const SUN_COMPETITION_ANS_4 = 'SunCompetition.answer4';
export const SUN_COMPETITION_ANS_5 = 'SunCompetition.answer5';
export const SUN_COMPETITION_ANS_6 = 'SunCompetition.answer6';
export const SUN_COMPETITION_ANS_7 = 'SunCompetition.answer7';
export const SUN_COMPETITION_ANS_8 = 'SunCompetition.answer8';
export const SUN_COMPETITION_SUN_STATUS = 'SunCompetition.status';
export const APP_ACD_URLS_REQUIRING_LOGGED_USER = [
  `/${RouteSlug.PHARMACIST_TEST_REPORT}`,
  `/${RouteSlug.PHARMACIST_TEST}`,
];

export const PAGE_TITLE_PREFIX = 'L’Oréal DB - ';
export const CONSENTS_DOM_ID = 'acd-my-data-consents';
